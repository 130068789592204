<template>
  <v-card
    :width="width"
    class="mx-auto"
    :class="{ shake: ui.shake }"
    color="white"
    elevation="4"
  >
    <v-form @submit.prevent="signIn">
      <v-card-title> ログイン </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="req.email"
          type="email"
          name="email"
          label="メールアドレス"
        ></v-text-field>
        <v-text-field
          v-model="req.password"
          @click:append="ui.showPassword = !ui.showPassword"
          :append-icon="ui.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="ui.showPassword ? 'text' : 'password'"
          name="password"
          label="パスワード"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="pa-4">
        <div class="error--text">{{ error }}</div>
        <v-spacer></v-spacer>
        <v-btn color="primary" type="submit" :loading="loading">ログイン</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { Hub } from 'aws-amplify'
import { models } from '@am-area/sdk'
const { Auth } = models
export default {
  props: {
    width: String,
  },
  data: () => ({
    ui: {
      showPassword: false,
      shake: false,
    },
    req: {
      email: null,
      password: null,
    },
    loading: false,
    error: null,
  }),
  methods: {
    async signIn() {
      this.ui.shake = false
      this.error = null
      this.loading = true
      try {
        const cognitoUser = await Auth.signIn(this.req.email, this.req.password)
        Hub.dispatch('Authenticator', {
          event: cognitoUser.challengeName || 'POST_SIGN_IN',
          message: '',
          data: {
            cognitoUser: cognitoUser,
          },
        })
      } catch (e) {
        switch (e.message) {
          case 'User does not exist.':
            this.error = 'メールアドレス、パスワードが一致しません。'
            break
          default:
            this.error = e.message
        }
        this.ui.shake = true
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
