<template>
  <Authenticator @authorized="startApp">
    <v-app>
      <NavPanel />

      <v-app-bar app dark color="primary" clipped-left style="z-index: 100">
        <div style="font-size: 1.6em">AiFace Area Marketing</div>
        <v-spacer />
        <v-menu
          v-model="showAccountInfoModal"
          left
          bottom
          offset-y
          style="z-index: 999"
          :close-on-click="true"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              text
              data-test="show-account-modal-btn"
              @click="showAccountInfoModal = true"
            >
              <v-icon>mdi-account-circle</v-icon>
              <span class="caption ml-2">{{ $root.user.email }}</span>
            </v-btn>
          </template>
          <AccountInfoModal @close="showAccountInfoModal = false" />
        </v-menu>
      </v-app-bar>

      <v-main class="">
        <v-toolbar v-if="!viewLoading" flat height="40">
          <v-breadcrumbs
            class="grey--text text--darken-1 font-weight-bold pb-1 pl-1"
            :items="breadcrumbs"
          />
        </v-toolbar>

        <router-view v-show="!viewLoading" />

        <v-snackbar
          v-if="this.$route.path !== '/home/contact'"
          light
          v-model="$root.isSaved_"
          :top="true"
          :right="true"
          timeout="2000"
        >
          保存しました
        </v-snackbar>
        <v-snackbar
          light
          v-model="$root.isNotValidated_"
          :top="true"
          :right="true"
          timeout="3000"
        >
          <span class="red--text"> 未入力箇所があり保存できません。 </span>
        </v-snackbar>
        <v-snackbar
          light
          v-model="$root.isError_"
          :top="true"
          :right="true"
          timeout="3000"
        >
          <span class="red--text">
            入力形式に不適切な箇所があり保存できません。
          </span>
        </v-snackbar>
        <v-overlay :value="viewLoading" absolute>
          <v-progress-circular
            v-if="viewLoading"
            class="mt-auto"
            color="primary"
            indeterminate
          ></v-progress-circular
        ></v-overlay>

        <footer class="text-center py-8 mt-8">
          <small class="text--disabled">© 2023 Paradigmshift inc.</small>
          <small
            @click="openTerms"
            class="text--disabled ml-10"
            style="cursor: pointer"
            >利用規約</small
          >
        </footer>
      </v-main>
    </v-app>
  </Authenticator>
</template>

<script>
import { models } from '@am-area/sdk'
const { Auth, Area, Hotel, Shop } = models
import Authenticator from '@/components/Authenticator.vue'
import NavPanel from '@/components/NavPanel'
import AccountInfoModal from '@/components/AccountInfoModal'
import Routes from '@/datas/routes'

export default {
  name: 'App',
  mixins: [],
  components: {
    Authenticator,
    NavPanel,
    AccountInfoModal,
  },

  async beforeRouteUpdate(to, from, next) {
    //
  },
  async created() {
    //
  },
  watch: {
    //
  },

  data: () => ({
    showNav: true,
    miniNav: false,
    showAccountInfoModal: false,
  }),
  computed: {
    user: {
      get() {
        return this.$root.user
      },
      set(user) {
        this.$root.user = user
      },
    },
    breadcrumbs() {
      let last = null
      if (this.$route.path == '/') return [{ text: 'HOME' }]
      return this.$route.path
        .split('/')
        .map((path) => {
          if (!path) return null
          const targets = !!last ? last.children : Routes
          const current = targets.find((o) => {
            let lastIndexOfPath = o.path.split('/')
            return lastIndexOfPath[lastIndexOfPath.length - 1] == path
          })
          last = current
          if (!current) {
            if (path === 'issued-tickets') return { text: '利用特典一覧' }
            else return { text: '詳細' }
          }
          return {
            text: current.name || null,
          }
        })
        .filter((o) => o)
    },
  },
  methods: {
    async startApp() {
      const user = await Auth.user()
      this.user = user
      await Promise.all([this.getAreas(), this.getHotels(), this.getShops()])
    },
    backToLogin(goToLogin) {
      this.modalClose()
      this.axiosError = null
      if (goToLogin) this.$router.push({ name: 'Login' })
    },
    openTerms() {
      const url = `https://rc-booking.com/RCBookingサービス利用規約.pdf`
      window.open(url, '_blank')
    },
    async getAreas() {
      const areas = await Area.index()
      this.$root.permission.area = areas.length > 0
    },
    async getHotels() {
      const hotels = await Hotel.index()
      this.$root.permission.hotel = hotels.length > 0
    },
    async getShops() {
      const shops = await Shop.index()
      this.$root.permission.shop = shops.length > 0
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/app';
@import '@/styles/print';
</style>
