<template>
  <v-dialog
    :width="width"
    :value="isOpen"
    :overlayOpacity="overlayOpacity"
    :overlayColor="overlayColor"
    :persistent="!closeOnClickOutside"
    @click="close()"
    @click:outside="closeOnClickOutside ? close() : ''"
  >
    <slot v-if="isOpen" name="default"></slot>
  </v-dialog>
</template>
<script>
export default {
  props: {
    width: { type: Number, default: 600 },
    overlayOpacity: { type: Number, default: 0.4 },
    name: { type: String },
    overlayColor: { type: String ,default: undefined},
    closeOnClickOutside: { type: Boolean, default: true }
  },
  computed: {
    isOpen() {
      return this.modalCheck(this.name)
    }
  },
  methods: {
    close() {
      this.modalClose();
      this.$emit('modal-close')
    }
  }
}
</script>