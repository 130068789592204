import dayjs from 'dayjs'

export default {
  install(vue) {
    console.log('Installing Filters plugin..')
    vue.mixin({
      filters: {
        fromtimestamp(val) {
          return dayjs.unix(val).format('YYYY.MM.DD HH:mm')
        },
        fromiso(val) {
          return dayjs(val).format('YYYY.MM.DD HH:mm')
        },
        number(val) {
          if (val === null) return '-'
          return Number(val).toLocaleString()
        },
        comma(val) {
          return `${val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`
        },
        date(val) {
          return dayjs(val).format('YYYY.MM.DD')
        },
        datetime(val) {
          return dayjs(val).format('YYYY.MM.DD HH:mm:ss')
        },
        lang(val) {
          switch (val) {
            case 'ja':
              return '日本語'
            case 'en':
              return '英語'
            case 'ko':
              return '韓国語'
            case 'zh_cn':
            case 'zh_CN':
              return '中国語（簡体）'
            case 'zh_tw':
            case 'zh_TW':
              return '中国語（繁体）'
            default:
              return 'エラー'
          }
        },
      },
    })
  },
}
