import Vue from 'vue'
import App from './App.vue'
import dayjs from 'dayjs'

import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import MainMixin from '@/mixins/Main.js'

import MainPlugin from '@/plugins/Main.js'
import ValidationPlugin from '@/plugins/Validations.js'
import FilterPlugin from '@/plugins/Filters.js'
import FramePlugin from '@/plugins/Frame.js'
import ConstantsPlugin from '@/plugins/Constants.js'
import AuthPlugin from '@/plugins/Auth.js'
import Required from '@/components/Required'
import VueApexCharts from 'vue-apexcharts'

import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'
Amplify.configure(awsconfig)

dayjs.locale('ja')
Vue.prototype.$dayjs = dayjs

// 開発用デバッグ情報 - リリース時コメントアウト！
Vue.config.productionTip = true
Vue.config.devtools = true

Vue.use(MainPlugin)
Vue.use(ValidationPlugin)
Vue.use(FilterPlugin)
Vue.use(FramePlugin)
Vue.use(ConstantsPlugin)
Vue.use(AuthPlugin)
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.component('Required', Required)

const instance = new Vue({
  router,
  mixins: [MainMixin],
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')

export default instance
