<style lang="scss">
.shake {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>

<template>
  <div>
    <template v-if="isAuthorized || $route.meta.unauth">
      <slot></slot>
    </template>
    <template v-else>
      <v-app>
        <v-app-bar app dark color="primary">
          <v-app-bar-title>AiFace Area Marketing</v-app-bar-title>
        </v-app-bar>
        <v-main style="height: 100vh" class="grey lighten-4">
          <v-container fill-height>
            <v-slide-x-transition mode="out-in">
              <div
                v-if="flow == 'SIGN_IN'"
                style="width: 480px"
                class="mx-auto"
              >
                <SignIn />
                <div class="text-right mt-2">
                  <a @click="flow = 'FORGOT_PASSWORD'">
                    パスワードをお忘れですか？
                  </a>
                </div>
              </div>
              <NewPasswordRequired
                v-if="flow == 'NEW_PASSWORD_REQUIRED'"
                width="480"
                :cognito-user="cognitoUser"
                class="mx-auto"
              />
              <ForgotPassword
                v-if="flow == 'FORGOT_PASSWORD'"
                @cancel="flow = 'SIGN_IN'"
                @completed="flow = 'SIGN_IN'"
                width="480"
                class="mx-auto"
              />
              <Emulation
                v-if="flow == 'EMULATION'"
                width="480"
                class="mx-auto"
              />
            </v-slide-x-transition>
          </v-container>
        </v-main>
      </v-app>
    </template>
  </div>
</template>

<script>
import { models } from '@am-area/sdk'
const { Auth } = models
import { Hub, Logger } from 'aws-amplify'
const logger = new Logger('Auth-Logger')
import SignIn from './Authenticator/SignIn.vue'
import NewPasswordRequired from './Authenticator/NewPasswordRequired.vue'
import ForgotPassword from './Authenticator/ForgotPassword.vue'
import Emulation from './Authenticator/Emulation.vue'
Logger.LOG_LEVEL = 'INFO'
export default {
  components: {
    SignIn,
    NewPasswordRequired,
    ForgotPassword,
    Emulation,
  },
  async created() {
    Hub.listen('Authenticator', async (data) => {
      console.info(`[AuthenticatorEvent] ${data.payload.event}`)
      switch (data.payload.event) {
        case 'POST_SIGN_IN':
          this.isAuthorized = true
          this.flow = 'SIGN_IN'
          break
        case 'NEW_PASSWORD_REQUIRED':
          this.cognitoUser = data.payload.data.cognitoUser
          this.flow = 'NEW_PASSWORD_REQUIRED'
          break
        case 'SIGN_IN_FAILED':
          //
          break
      }
      this.mode = data.payload.event
    })
    Hub.listen('auth', async (data) => {
      console.log(data)
      switch (data.payload.event) {
        // 認証成功
        case 'signIn':
          logger.info('user signed in')
          if (Auth.cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.flow = 'NEW_PASSWORD_REQUIRED'
          }
          break
        // 認証失敗
        case 'signIn_failure':
          logger.error('user sign in failed')
          this.ui.shake = true
          break
        // ログアウト
        case 'signOut':
          logger.info('user signed out')
          this.isAuthorized = false
          // localStorage.removeItem('emulationUserId')
          localStorage.clear()
          break
        case 'signUp':
          logger.info('user signed up')
          break
        case 'tokenRefresh':
          logger.info('token refresh succeeded')
          break
        case 'tokenRefresh_failure':
          logger.error('token refresh failed')
          break
        case 'configured':
          logger.info('the Auth module is configured')
      }
    })
    if (this.$route.path == '/emulation') {
      await this.emulation()
    }
    this.isAuthorized = await Auth.check()
  },
  watch: {
    $route() {
      if (this.$route.path == '/emulation') {
        this.emulation()
      }
    },
    isAuthorized(val) {
      if (val) this.$emit('authorized')
    },
  },
  data: () => ({
    isAuthorized: false,
    cognitoUser: {},
    flow: 'SIGN_IN',
    ui: {
      showPassword: false,
      shake: false,
    },
    req: {
      email: null, //'user@psinc.jp',
      password: null, //'psincpsinc',
    },
  }),
  methods: {
    async emulation() {
      this.flow = 'EMULATION'
      try {
        await this.$nextTick()
        // await Auth.signOut()
        localStorage.clear()

        const emulationUserId = this.$route.query.user
        localStorage.setItem('emulationUserId', emulationUserId)

        await Auth.signIn('admin@psinc.jp', 'psincpsinc')
        // Adminチェック - 固定にしたので不要
        // const groups = await Auth.groups()
        // const isAdmin = groups.findIndex((group) => group == 'Admin') > -1

        this.isAuthorized = true
      } catch (e) {
        alert(e.messsage)
      } finally {
        this.$router.replace('/')
        this.flow = 'SIGN_IN'
      }
    },
  },
}
</script>
