import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [],
  },
  {
    path: '/analysis',
    component: () => import('@/views/Home/Analysis.vue'),
  },

  /**
   * Area
   */

  {
    path: '/areas',
    component: () => import('@/views/Home/Areas.vue'),
  },
  {
    path: '/areas/:areaId',
    component: () => import('@/views/Home/Areas/Area.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Home/Areas/Area/Edit.vue'),
      },
      {
        path: 'shops',
        component: () => import('@/views/Home/Areas/Area/Shops.vue'),
      },
      {
        path: 'issued-ticket-analysis/:ticketId',
        component: () =>
          import('@/views/Home/Areas/Area/IssuedTicketAnalysis.vue'),
      },
      {
        path: 'issued-tickets',
        component: () => import('@/views/Home/Areas/Area/IssuedTickets.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/Home/Areas/Area/IssuedTickets/IssuedTickets.vue'),
          },
          {
            path: ':ticketId',
            component: () =>
              import('@/views/Home/Areas/Area/IssuedTickets/IssuedTicket.vue'),
          },
        ],
      },
      // {
      //   path: 'analysis',
      //   component: () => import('@/views/Home/Areas/Area/IssuedTickets/IssuedTicket.vue')
      // },
    ],
  },

  /**
   * Hotel
   */

  {
    path: '/hotels',
    component: () => import('@/views/Home/Hotels.vue'),
  },
  {
    path: '/hotels/:hotelId',
    component: () => import('@/views/Home/Hotels/Hotel.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Home/Hotels/Hotel/Edit.vue'),
      },
      {
        path: 'issued-ticket-analysis/:ticketId',
        component: () =>
          import('@/views/Home/Hotels/Hotel/IssuedTicketAnalysis.vue'),
      },
      {
        path: 'guests',
        component: () => import('@/views/Home/Hotels/Hotel/Guests.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/Home/Hotels/Hotel/Guests/Guests.vue'),
          },
          {
            path: ':guestId',
            component: () =>
              import('@/views/Home/Hotels/Hotel/Guests/Guest.vue'),
          },
        ],
      },
      // analysisへ
      {
        path: 'issued-tickets',
        component: () => import('@/views/Home/Hotels/Hotel/IssuedTickets.vue'),
        children: [
          {
            path: '',
            component: () =>
              import(
                '@/views/Home/Hotels/Hotel/IssuedTickets/IssuedTickets.vue'
              ),
          },
          {
            path: ':ticketId',
            component: () =>
              import(
                '@/views/Home/Hotels/Hotel/IssuedTickets/IssuedTicket.vue'
              ),
          },
        ],
      },
      // ↓↓↓ こっちへ集約
      // {
      //   path: 'analysis',
      //   component: () => import('@/views/Home/Hotels/Hotel/Analysis.vue'),
      // },
    ],
  },

  /**
   * Shop
   */

  {
    path: '/shops',
    component: () => import('@/views/Home/Shops.vue'),
  },
  {
    path: '/shops/:shopId',
    component: () => import('@/views/Home/Shops/Shop.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Home/Shops/Shop/Edit.vue'),
      },
      {
        path: 'issued-ticket-analysis/:ticketId',
        component: () =>
          import('@/views/Home/Shops/Shop/IssuedTicketAnalysis.vue'),
      },
      {
        path: 'tickets',
        component: () => import('@/views/Home/Shops/Shop/Tickets.vue'),
      },
      {
        path: 'tickets/:ticketId',
        component: () => import('@/views/Home/Shops/Shop/Tickets/Ticket.vue'),
      },
      // analysisへ
      {
        path: 'issued-tickets',
        component: () => import('@/views/Home/Shops/Shop/IssuedTickets.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/Home/Shops/Shop/IssuedTickets/IssuedTickets.vue'),
          },
          {
            path: ':ticketId',
            component: () =>
              import('@/views/Home/Shops/Shop/IssuedTickets/IssuedTicket.vue'),
          },
        ],
      },
      // ↓↓↓ こっちへ集約
      // {
      //   path: 'analysis',
      //   component: () => import('@/views/Home/Shops/Shop/Analysis.vue'),
      // },
    ],
  },

  // {
  //   path: '/shops/:id/guests',
  //   component: () => import('@/views/Home/Shops/Shop/Guests.vue'),
  //   children: [
  //     {
  //       path: '',
  //       component: () =>
  //         import('@/views/Home/Shops/Shop/Guests/Guests.vue'),
  //     },
  //     {
  //       path: ':guestId',
  //       component: () =>
  //         import('@/views/Home/Shops/Shop/Guests/Guest.vue'),
  //     },
  //   ],
  // },

  /**
   * User
   */

  {
    path: '/account',
    component: () => import('@/views/Home/Accounts.vue'),
    children: [
      {
        path: '',
        redirects: 'email',
      },
      {
        path: 'email',
        component: () => import('@/views/Home/Accounts/AccountEmail.vue'),
      },
      {
        path: 'password',
        component: () => import('@/views/Home/Accounts/AccountPassword.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
