<template>
  <div>
    <v-slide-x-transition mode="out-in">
      <v-card v-if="!ui.emailSubmited" :width="width" class="mx-auto">
        <v-form @submit.prevent="forgotPassword">
          <v-card-title>パスワード再設定</v-card-title>
          <v-card-subtitle
            >ログインに使用するメールアドレスを入力して下さい。</v-card-subtitle
          >
          <v-card-text>
            <v-text-field
              v-model="req.email"
              type="email"
              name="email"
              label="メールアドレス"
              :error-messages="error.forgotPassword"
            ></v-text-field>
            <!-- <v-text-field
            v-model="req.password"
            @click:append="ui.showPassword = !ui.showPassword"
            :append-icon="ui.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="ui.showPassword ? 'text' : 'password'"
            name="password"
            label="パスワード"
          ></v-text-field> -->
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn @click="$emit('cancel')" class="mr-2">キャンセル</v-btn>
            <v-btn color="primary" type="submit" :loading="ui.loading"
              >確認する</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
      <v-card
        v-else
        :width="width"
        class="mx-auto"
        :class="{ shake: ui.shake }"
      >
        <v-form @submit.prevent="forgotPasswordSubmit">
          <v-card-title>新しいパスワードの設定</v-card-title>
          <v-card-subtitle>
            入力されたメールアドレスに検証コードを送信しました。<br />
          </v-card-subtitle>
          <v-card-text>
            <section>
              <header class="mb-2">
                メールに添付された検証コードを入力して下さい。
              </header>
              <v-otp-input v-model="req.code" length="6"></v-otp-input>
              <footer class="d-flex">
                <v-spacer></v-spacer>
                <v-btn small color="warning">検証コードを再送信する</v-btn>
              </footer>
            </section>
            <section class="mt-4">
              <div class="text-caption">
                ※8文字以上で、大文字、小文字、数字、シンボル("&amp;@#$%!*)1つを含めて設定してください。
              </div>
              <v-text-field
                v-model="req.newPassword"
                @click:append="ui.showPassword = !ui.showPassword"
                :append-icon="ui.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="ui.showPassword ? 'text' : 'password'"
                name="password"
                label="新しいパスワード"
                :rules="[formRules.required, formRules.password]"
              ></v-text-field>
              <v-text-field
                v-model="req.newPasswordConfirm"
                @click:append="ui.showPassword = !ui.showPassword"
                :append-icon="ui.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="ui.showPassword ? 'text' : 'password'"
                name="password"
                label="新しいパスワード（確認用）"
                :rules="[formRules.required, formRules.password]"
              ></v-text-field>
            </section>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn @click="ui.emailSubmited = false" class="mr-2"
              >キャンセル</v-btn
            >
            <v-btn color="primary" type="submit" :loading="ui.loading"
              >設定する</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-slide-x-transition>
  </div>
</template>

<script>
// import { Hub } from "aws-amplify";
// import { models } from '@am-area/sdk'
// const { Auth } = models
import { Auth } from 'aws-amplify'
export default {
  props: {
    width: String,
  },
  data: () => ({
    ui: {
      loading: false,
      emailSubmited: false,
      showPassword: false,
      shake: false,
    },
    req: {
      email: null,
      code: null,
      newPassword: null,
      newPasswordConfirm: null,
    },
    error: {
      forgotPassword: null,
      forgotPasswordSubmit: null,
    },
  }),
  methods: {
    async forgotPassword() {
      this.ui.loading = true
      this.error.forgotPassword = null
      try {
        const username = this.req.email
        const res = await Auth.forgotPassword(username)
        // console.log(res)
        this.ui.emailSubmited = true
      } catch (e) {
        this.error.forgotPassword = '一致するメールアドレスがありません。'
      } finally {
        this.ui.loading = false
      }
    },
    async forgotPasswordSubmit() {
      this.ui.loading = true
      this.error.forgotPasswordSubmit = null
      try {
        const username = this.req.email
        const code = this.req.code
        const newPassword = this.req.newPassword
        const newPasswordConfirm = this.req.newPasswordConfirm
        if (newPassword != newPasswordConfirm) {
          throw new Error('確認用パスワードが一致しません。')
        }
        const res = await Auth.forgotPasswordSubmit(username, code, newPassword)
        console.log(res)
        this.$emit('completed')
      } catch (e) {
        this.error.forgotPasswordSubmit = e.message
      } finally {
        this.ui.loading = false
      }
    },
  },
}
</script>
