<template>
  <v-navigation-drawer
    permanent
    :mini-variant="miniNav"
    color="grey lighten-4"
    app
    clipped
    width="250"
  >
    <nav>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon v-on:click="miniNav = !miniNav">mdi-menu</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>
        <template v-for="(route, index) in routes">
          <!-- 多階層 -->
          <template
            v-if="
              route.path == '/account' &&
              route.children &&
              route.children.length
            "
          >
            <v-list-group no-action :key="index" :prepend-icon="route.icon">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="route.name" />
                </v-list-item-content>
              </template>
              <template v-for="child in route.children">
                <template>
                  <v-list-item :key="child.label" :to="child.path">
                    <v-list-item-content>
                      <v-list-item-title v-text="child.name" />
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </template>
            </v-list-group>
          </template>
          <!-- １階層 -->
          <template v-else>
            <v-list-item :key="route.name" :to="route.path">
              <v-list-item-icon>
                <v-icon v-text="route.icon" />
              </v-list-item-icon>
              <v-list-item-title v-text="route.name" />
            </v-list-item>
          </template>
        </template>
      </v-list>
    </nav>
  </v-navigation-drawer>
</template>

<script>
import Routes from '@/datas/routes'
export default {
  components: {},
  data: () => ({
    miniNav: false,
  }),
  computed: {
    routes() {
      return Routes.filter((route) => route.type == 'main').filter((route) => {
        if (!route.permission) return true
        return this.$root.permission[route.permission]
      })
    },
  },
}
</script>
