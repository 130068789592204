<template>
  <v-card
    :width="width"
    class="mx-auto"
    dark
    color="primary darken-1"
    elevation="4"
  >
    <v-card-title> Adminログイン </v-card-title>
    <v-card-subtitle>
      <span class="font-weight-bold">{{ emulationUserEmail }}</span>
      アカウントをエミュレーションします。
    </v-card-subtitle>
    <v-container>
      <v-row>
        <v-col class="d-flex">
          <v-progress-circular
            indeterminate
            color="white"
            class="mx-auto my-4"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    width: String,
  },
  computed: {
    emulationUserId() {
      return this.$route.query.user
    },
    emulationUserEmail() {
      return this.$route.query.email
    },
  },
}
</script>
