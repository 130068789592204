export default {
  async created() {
    //
  },
  data() {
    return {
      user: {},
      permission: {
        area: false,
        hotel: false,
        shop: false,
      },
      viewLoading_: false, // 各ビューでのデータロード中を表すステータス
      modal: null, // 表示中のモーダル名
      isNotCreateUcPassCode_: false,
      axiosError_: null, // error
      isSaved_: false, // "保存しました"を右上に表示
      isNotValidated_: false, // "フォームバリデーションエラー"を右上に表示
      isError_: false, // "フォームバリデーションエラー"を右上に表示
      guestPageLangs_: [], // ゲストページ使用言語
    }
  },
  methods: {
    //
  },
}
