export default {
  install(vue) {
    console.log('Installing Main plugin..')
    vue.mixin({
      data() {
        return {
          dataReady: false,
          forgetPass: {
            email: '',
          },
        }
      },
      computed: {
        axiosError: {
          get() {
            return this.$root.axiosError_
          },
          set(val) {
            this.$root.axiosError_ = val
          },
        },
        viewLoading: {
          get: function () {
            return this.$root.viewLoading_
          },
          set: function (val) {
            this.$root.viewLoading_ = val
          },
        },
      },
      methods: {
        trimParams(params) {
          const query = {}
          Object.keys(params).map((key) => {
            if (params[key] !== null && params[key] !== '')
              query[key] = params[key]
          })
          return query
        },
        getAge(age) {
          switch (age) {
            case 0:
              return '13才未満'
            case 10:
              return '13〜19才'
            case 20:
              return '20代'
            case 30:
              return '30代'
            case 40:
              return '40代'
            case 50:
              return '50代'
            case 60:
              return '60代'
            case 70:
              return '70代'
            case 80:
              return '80才以上'
            default:
              return '不明'
          }
        },
        AttrText(guestAttr, attrs) {
          return guestAttr
            ? attrs
                .filter((o) => o.id == guestAttr)
                .map((d) => d.text)
                .join(' ')
            : '未回答'
        },
        AttrTextForTickets(ticketAttr, attrs) {
          return ticketAttr
            ? attrs
                .filter((o) =>  ticketAttr.includes(o.id))
                .map((d) => d.text)
                .join(' / ')
            : '未回答'
        },
      },
    })
  },
}
