<template>
  <v-card class="elevation-6 py-4" style="width: 250px" outlined>
    <v-card-text class="text-center">
      <v-icon large>mdi-account-circle</v-icon>
      <div class="text-subtitle-1 mt-4">
        {{ `${$root.user.family_name} ${$root.user.given_name}` }}様
      </div>
      <div class="text-subtitle-1">{{ $root.user.email }}</div>
      <div class="text-center mt-6">
        <v-btn color="primary" data-test="logout-btn" @click="signOut">
          ログアウト
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { models } from '@am-area/sdk'
const { Auth } = models

export default {
  methods: {
    async signOut() {
      await Auth.signOut()
    },
  },
}
</script>
