<template>
<div class="ml-8">TOP PAGE</div>
  <!-- <v-container>
    <div style="max-width: 950px;" class="mb-5 mx-auto">
      <guest-card-delete-alert />
    </div>
    <v-card max-width="950px" class="mx-auto">
      <v-card-title class="py-3" style="background: #f5f5f5">
        <h1 class="font-weight-bold" style="font-size: 12px">
          Information
        </h1>
      </v-card-title>
      <div class="pt-5">
        <div v-for="post in posts" :key="post.id" class="item">
          <v-card-text style="font-size: 1.2rem;">
            <div class="d-flex align-center mb-4">
              <span class="font-weight-bold mr-2" style="font-size: 12px">{{
                post.title
              }}</span>
            </div>
            <div
              v-text="post.content"
              class="mb-4"
              style="word-break: break-all; white-space: pre-wrap; font-size: 11.5px; line-height: 1.8rem"
            ></div>
            <v-chip x-small color="#f5f5f5">{{
              post.publishedAt.substr(0, 10)
            }}</v-chip>
          </v-card-text>
          <v-divider class="my-5"></v-divider>
        </div>
        <div v-if="!posts.length">
          <v-card-text style="font-size: 1.2rem;">
            <div class="d-flex align-center mb-4">
              <span class="mr-2" style="font-size: 12px"
                >公開中のInformationはありません。</span
              >
            </div>
          </v-card-text>
        </div>
      </div>
    </v-card>
  </v-container> -->
</template>

<script>
export default {
  mixins: [],
  components: {
    //
  },
  data: () => ({

  }),
  computed: {
    //
  },
  methods: {
    //
  },
};
</script>
