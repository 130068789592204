export default {
  install(vue) {
    console.log('Installing Constants plugin..')
    vue.mixin({
      computed: {
        LANGS() {
          return [
            { value: 'ja', text: '日本語' },
            { value: 'en', text: '英語' },
            { value: 'ko', text: '韓国語' },
            { value: 'zh_CN', text: '中国語(簡体)' },
            { value: 'zh_TW', text: '中国語(繁体)' },
          ]
        },
        attr_Total() {
          //
          return [
            { id: 'attr_transportation@PCA', text: '自家用車' },
            { id: 'attr_transportation@RCA', text: 'レンタカー' },
            { id: 'attr_transportation@BU', text: 'バス' },
            { id: 'attr_transportation@TR', text: '電車' },
            { id: 'attr_transportation@TX', text: 'タクシー' },
            { id: 'attr_transportation@TF', text: '送迎' },
            { id: 'attr_transportation@OTH', text: 'その他' },
            { id: 'attr_purpose@ST', text: '観光' },
            { id: 'attr_purpose@LS', text: 'レジャー' },
            { id: 'attr_purpose@BJ', text: 'ビジネス' },
            { id: 'attr_purpose@TE', text: '受験' },
            { id: 'attr_purpose@CM', text: '冠婚葬祭' },
            { id: 'attr_purpose@ET', text: '教育旅行' },
            { id: 'attr_purpose@SO', text: '学会' },
            { id: 'attr_purpose@TC', text: '合宿' },
            { id: 'attr_purpose@HC', text: '湯治' },
            { id: 'attr_purpose@OTH', text: 'その他' },
            { id: 'attr_customer_base@FA', text: 'ファミリー' },
            { id: 'attr_customer_base@OC', text: '夫婦' },
            { id: 'attr_customer_base@CU', text: 'カップル' },
            { id: 'attr_customer_base@OP', text: '一人' },
            { id: 'attr_customer_base@GP', text: 'グループ' },
            { id: 'attr_customer_base@PT', text: 'ペット' },
            { id: 'attr_customer_base@CH', text: '子供連れ' },
            { id: 'attr_customer_base@OTH', text: 'その他' },
            { id: 'attr_travel_type@OH', text: 'ホテオン' },
            { id: 'attr_travel_type@DPK', text: 'DP（航空）' },
            { id: 'attr_travel_type@DPT', text: 'DP（電車）' },
            { id: 'attr_travel_type@DPO', text: 'DP（その他）' },
            { id: 'attr_travel_type@PAN', text: '企画' },
            { id: 'attr_travel_type@RET', text: '募集' },
            { id: 'attr_travel_type@OTH', text: 'その他' },
            { id: 'attr_market@FIT', text: '個人' },
            { id: 'attr_market@GRO', text: '団体' },
            { id: 'attr_market@MEB', text: '会員' },
            { id: 'attr_market@HUS', text: 'ハウスユース' },
            { id: 'attr_market@COMP', text: 'コンプリメンタリー' },
            { id: 'attr_market@OTH', text: 'その他' },
            { id: 'attr_channel@OTA', text: '国内OTA' },
            { id: 'attr_channel@OTI', text: '海外OTA' },
            { id: 'attr_channel@RLA', text: '国内旅行会社' },
            { id: 'attr_channel@INA', text: '海外旅行会社' },
            { id: 'attr_channel@HRA', text: '非旅行会社' },
            { id: 'attr_channel@CCA', text: '契約法人' },
            { id: 'attr_channel@HPA', text: '自社' },
            { id: 'attr_channel@OTH', text: 'その他' },
            { id: 'attr_source@SC', text: 'サイトコントローラー' },
            { id: 'attr_source@RSV', text: '予約センター' },
            { id: 'attr_source@SAL', text: '営業' },
            { id: 'attr_source@WIN', text: 'walkin' },
            { id: 'attr_source@FO', text: 'フロント' },
            { id: 'attr_source@REF', text: '紹介' },
            { id: 'attr_source@OTH', text: 'その他' },
            { id: 'attr_from@WEB', text: 'WEB' },
            { id: 'attr_from@SNS', text: 'SNS/メール' },
            { id: 'attr_from@TEL', text: '電話' },
            { id: 'attr_from@FAX', text: 'FAX' },
            { id: 'attr_from@MET', text: '対面' },
            { id: 'attr_from@OTH', text: 'その他' },
          ]
        },
        attr_TRANSPORTATION() {
          // 交通手段
          return [
            { id: 'attr_transportation@PCA', text: '自家用車' },
            { id: 'attr_transportation@RCA', text: 'レンタカー' },
            { id: 'attr_transportation@BU', text: 'バス' },
            { id: 'attr_transportation@TR', text: '電車' },
            { id: 'attr_transportation@TX', text: 'タクシー' },
            { id: 'attr_transportation@TF', text: '送迎' },
            { id: 'attr_transportation@OTH', text: 'その他' },
          ]
        },
        attr_PURPOSE() {
          // 利用目的
          return [
            { id: 'attr_purpose@ST', text: '観光' },
            { id: 'attr_purpose@LS', text: 'レジャー' },
            { id: 'attr_purpose@BJ', text: 'ビジネス' },
            { id: 'attr_purpose@TE', text: '受験' },
            { id: 'attr_purpose@CM', text: '冠婚葬祭' },
            { id: 'attr_purpose@ET', text: '教育旅行' },
            { id: 'attr_purpose@SO', text: '学会' },
            { id: 'attr_purpose@TC', text: '合宿' },
            { id: 'attr_purpose@HC', text: '湯治' },
            { id: 'attr_purpose@OTH', text: 'その他' },
          ]
        },
        attr_CUSTOMERBASE() {
          // 客層
          return [
            { id: 'attr_customer_base@FA', text: 'ファミリー' },
            { id: 'attr_customer_base@OC', text: '夫婦' },
            { id: 'attr_customer_base@CU', text: 'カップル' },
            { id: 'attr_customer_base@OP', text: '一人' },
            { id: 'attr_customer_base@GP', text: 'グループ' },
            { id: 'attr_customer_base@PT', text: 'ペット' },
            { id: 'attr_customer_base@CH', text: '子供連れ' },
            { id: 'attr_customer_base@OTH', text: 'その他' },
          ]
        },
        attr_TRAVELTYPE() {
          // 旅行タイプ
          return [
            { id: 'attr_travel_type@OH', text: 'ホテオン' },
            { id: 'attr_travel_type@DPK', text: 'DP（航空）' },
            { id: 'attr_travel_type@DPT', text: 'DP（電車）' },
            { id: 'attr_travel_type@DPO', text: 'DP（その他）' },
            { id: 'attr_travel_type@PAN', text: '企画' },
            { id: 'attr_travel_type@RET', text: '募集' },
            { id: 'attr_travel_type@OTH', text: 'その他' },
          ]
        },
        attr_MARKET() {
          // マーケット
          return [
            { id: 'attr_market@FIT', text: '個人' },
            { id: 'attr_market@GRO', text: '団体' },
            { id: 'attr_market@MEB', text: '会員' },
            { id: 'attr_market@HUS', text: 'ハウスユース' },
            { id: 'attr_market@COMP', text: 'コンプリメンタリー' },
            { id: 'attr_market@OTH', text: 'その他' },
          ]
        },
        attr_CHANNEL() {
          // 販路タイプ
          return [
            { id: 'attr_channel@OTA', text: '国内OTA' },
            { id: 'attr_channel@OTI', text: '海外OTA' },
            { id: 'attr_channel@RLA', text: '国内旅行会社' },
            { id: 'attr_channel@INA', text: '海外旅行会社' },
            { id: 'attr_channel@HRA', text: '非旅行会社' },
            { id: 'attr_channel@CCA', text: '契約法人' },
            { id: 'attr_channel@HPA', text: '自社' },
            { id: 'attr_channel@OTH', text: 'その他' },
          ]
        },
        attr_SOURCE() {
          // ソース
          return [
            { id: 'attr_source@SC', text: 'サイトコントローラー' },
            { id: 'attr_source@RSV', text: '予約センター' },
            { id: 'attr_source@SAL', text: '営業' },
            { id: 'attr_source@WIN', text: 'walkin' },
            { id: 'attr_source@FO', text: 'フロント' },
            { id: 'attr_source@REF', text: '紹介' },
            { id: 'attr_source@OTH', text: 'その他' },
          ]
        },
        attr_FROM() {
          // 予約経路
          return [
            { id: 'attr_from@WEB', text: 'WEB' },
            { id: 'attr_from@SNS', text: 'SNS/メール' },
            { id: 'attr_from@TEL', text: '電話' },
            { id: 'attr_from@FAX', text: 'FAX' },
            { id: 'attr_from@MET', text: '対面' },
            { id: 'attr_from@OTH', text: 'その他' },
          ]
        },
        CONDITIONS() {
          return [{ value: 'useDate', text: '利用日' }]
        },
        CONDITION_FOR_GUEST() {
          return [{ value: 'useDate', text: 'チェックイン日' }]
        },
        PERIODS() {
          return [
            { value: 'range', text: '指定期間' },
            { value: '1', text: '直近1ヶ月' },
            { value: '3', text: '直近3ヶ月' },
            { value: '6', text: '直近6ヶ月' },
            { value: '12', text: '直近12ヶ月' },
          ]
        },
        PERIODS() {
          return [
            { value: 'range', text: '指定期間' },
            { value: '365-0', text: '直近1年間' },
            { value: '183-0', text: '直近6ヶ月間' },
            { value: '31-0', text: '直近1ヶ月間' },
            { value: '7-0', text: '直近1週間' },
            { value: '1-0', text: '直近2日間' },
            { value: '0', text: '今日' },
            { value: '0+1', text: '本日から翌日まで' },
            { value: '0+7', text: '本日から1週間先まで' },
            { value: '0+31', text: '本日から1ヶ月先まで' },
            { value: '0+183', text: '本日から6ヶ月先まで' },
            { value: '0+365', text: '本日から1年先まで' },
          ]
        },
        DAYS_OF_WEEK() {
          return [
            { value: 'Sunday', text: '日', isChecked: true },
            { value: 'Monday', text: '月', isChecked: true },
            { value: 'Tuesday', text: '火', isChecked: true },
            { value: 'Wednesday', text: '水', isChecked: true },
            { value: 'Thursday', text: '木', isChecked: true },
            { value: 'Friday', text: '金', isChecked: true },
            { value: 'Saturday', text: '土', isChecked: true },
            { value: 'Holiday', text: '祝日', isChecked: true },
            { value: 'BeforeHoliday', text: '祝前日', isChecked: true },
          ]
        },
        SEARCH_AGES() {
          return [
            { value: '1', text: '13才未満' },
            { value: '10', text: '13〜19才' },
            { value: '20', text: '20代' },
            { value: '30', text: '30代' },
            { value: '40', text: '40代' },
            { value: '50', text: '50代' },
            { value: '60', text: '60代' },
            { value: '70', text: '70代' },
            { value: '80', text: '80才以上' },
          ]
        },
        SEARCH_SEXES() {
          return [
            { value: 'male', text: '男性' },
            { value: 'female', text: '女性' },
            { value: 'NA', text: '未回答' },
          ]
        },
        SEARCH_RATINGS() {
          return [
            { value: '1', text: '★1' },
            { value: '2', text: '★2' },
            { value: '3', text: '★3' },
            { value: '4', text: '★4' },
            { value: '5', text: '★5' }
          ]
        },
      },
    })
  },
}
