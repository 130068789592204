import FrameModal from '@/components/FrameModal'
export default {
  install(Vue){   
    console.log('Installing Frame plugin..')
    Vue.component('FrameModal', FrameModal)
    Vue.mixin({
      computed: {
        modalName() {
          return this.$root.modal
        }
      },
      methods: {
        modalShow(name) {
          this.$root.modal = name
        },
        modalCheck(name) {
          return this.$root.modal === name 
        },
        modalClose() {
          this.$root.modal = null
          this.$emit('modal-close')
        },
        showErrorModal(error) {
          // const { status, data } = error.response
          this.$root.modal = 'axios-error'
          this.viewLoading = false
          this.axiosError = error
        },
        showSavedNotification() {
          if (this.$root.isSaved_) return
          this.$root.isSaved_ = true
        },
      },
    })
  }
}