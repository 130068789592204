export default {
  install(vue) {
    console.log('Installing Validation plugin..')
    vue.mixin({
      computed: {
        formRules: {
          get() {
            return {
              required: (v) =>
                v === false || v === 0 || !!v || (typeof v === Array && !v.length) || "必須項目です。", // false と 0　はOK
              url: (v) =>
                !v ||
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
                  v
                ) ||
                '不正なURLです。',
              email: v => /.+@.+\..+/.test(v) || 'メールアドレスが不正です',
              phone: v => /\d+/.test(v) || '数字のみで入力してください。',
              specialCode: v =>
                (v !== null && /^[A-Za-z0-9]{6,10}$/.test(v)) ||
                '6文字以上、10文字以下の英数字で入力してください。',
              password: v =>
                /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\"\&\@\#\$\%\!\*]).{8,20})/.test(
                  v
                ) || 'パスワード要件を満たしていません。',
              passCodeUcCard: v =>
                (v !== null && /^[A-Za-z0-9]{4,12}$/.test(v)) ||
                '4〜12文字の半角英数字で入力してください。',
              requiredWhenOneInputed: () => false,
            }
          },
        },
      },
      methods: {
        maxChars(max) {
          return v =>
            v == null || v.length <= max || `${max}文字以内で入力してください。`
        },
        // 半角文字数制限（最小値）、全角の場合lengthを2とするもの
        validateMinlength(minLength) {
          return v => {
            let len = 0
            let min = minLength
            for (let i = 0; i < v.length; i++) {
              if (v[i].match(/[ -~]/) ? (len += 1) : (len += 2));
            }
            if (v == null || len >= min) return true
            else return `半角${minLength}文字以上で入力してください。`
          }
        },
        // 半角文字数制限（最大値）、全角の場合lengthを2とするもの
        validateMaxlength(maxLength) {
          return v => {
            let len = 0
            let max = maxLength
            for (let i = 0; i < v.length; i++) {
              if (v[i].match(/[ -~]/) ? (len += 1) : (len += 2));
            }
            if (v == null || len <= max) return true
            else return `半角${maxLength}文字以下で入力してください。`
          }
        },
        validateInteger() {
          return v => {
            let val = Number(v)
            if (Number.isInteger(val) || !val || val == '0') {
              return true
            } else return '小数値が入力されています'
          }
        },
        validateMinus() {
          return v => {
            let val = Number(v)
            if (val >= 0) {
              return true
            } else return 'マイナスの値が入力されています'
          }
        },
        validateIntegerShort() {
          return v => {
            let val = Number(v)
            if (Number.isInteger(val) || !val || val == '0') {
              return true
            } else return '小数値'
          }
        },
        validateMinusShort() {
          return v => {
            let val = Number(v)
            if (val >= 0) {
              return true
            } else return 'マイナス値'
          }
        },
      },
    })
  },
}
